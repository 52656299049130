<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="mb-3">
                    <div v-show="loading == true" class="alert alert-warning" role="alert">
                        <div class="alert-heading text-center mb-0">
                            <div class="spinner-border mr-2 align-middle" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <span>Carregando, aguarde...</span>
                        </div>
                    </div><!-- /alert-warning -->
                    <div v-show="error.length > 0" class="alert alert-danger" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">{{error}}</p>
                    </div><!-- /alert-danger -->
                    <div class="w-100" v-show="leagues.length > 0">
                        <div class="row ml-0 mb-3 mr-0">
                            <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                <div class="btn-group w-100" :class="{'mb-3': platform == 'mobile'}" role="group" aria-label="Basic example">
                                    <button type="button" class="btn btn-secondary" :class="{'active': status == 0}" @click="status = 0"><i class="fas fa-list mr-2" v-show="platform == 'desktop'"></i>Todas</button>
                                    <button type="button" class="btn btn-secondary" :class="{'active': status == 1}" @click="status = 1"><i class="fas fa-eye mr-2" v-show="platform == 'desktop'"></i>Ativa</button>
                                    <button type="button" class="btn btn-secondary" :class="{'active': status == 2}" @click="status = 2"><i class="fas fa-eye-slash mr-2" v-show="platform == 'desktop'"></i>Desativada</button>
                                </div>
                            </div><!-- /col -->
                            <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="text" v-model="search" placeholder="Buscar liga...">
                            </div><!-- /col -->
                            <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                                <button class="btn btn-warning btn-block" @click="save"><i class="fas fa-save mr-2"></i>Salvar alterações</button>
                            </div><!-- /col -->
                        </div><!-- /row -->
                        <template v-if="filteredListLeagues.length == 0">
                            <div class="alert alert-danger" role="alert">
                                <h4 class="alert-heading">Ops, nenhum resultado!</h4>
                                <hr />
                                <p class="mb-0">Não encontramos nenhuma liga com o nome "<strong>{{search}}</strong>". Verifique o nome e tente novamente!</p>
                            </div><!-- /alert-danger -->
                        </template>
                        <template v-else>
                            <div class="w-100 clearfix">
                                <div class="list-group list-group-flush">
                                    <collection-cluster v-bind="collection" :items="filteredListLeagues" :style="{height: screen_innerHeight + 'px'}" :class="{'desktop pr-3': platform == 'desktop', 'rounded': !loading}">
                                        <div class="list-group-item" slot="item" slot-scope="{ cell, item }" :key="cell.index" :class="{'bg-danger text-white': String(filteredListLeagues[cell.index].pre_jogo) == '2'}" :ref="item.id">
                                            <div class="row" :class="{'justify-content-center': platform == 'mobile'}">
                                                <div class="col-auto d-flex align-items-center pr-0" v-if="platform == 'desktop'">
                                                    <img class="align-text-bottom rounded-pill" :src="`${image_server}/f/${imageFlag(item.nome)}`" @error="imageError($event, '/images/league_default.svg')" width="20" border="0">
                                                </div>
                                                <div class="col text-truncate text-primary" :class="{'col-12 mb-2 text-center': platform == 'mobile', 'd-flex align-items-center': platform == 'desktop'}">
                                                    <div class="text-truncate" :class="{'text-white': String(filteredListLeagues[cell.index].pre_jogo) == '2'}">{{item.nome}}</div>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="d-flex" :class="{'flex-column align-items-center': platform == 'mobile', 'flex-row align-items-center': platform == 'desktop'}">
                                                        <div :class="{'mb-2 text-center': platform == 'mobile', 'mr-3': platform == 'desktop'}">Pré-jogo</div>
                                                        <div>
                                                            <select class="form-control form-control-sm w-auto" v-model="filteredListLeagues[cell.index].pre_jogo">
                                                                <option value="1" :selected="item.pre_jogo == 1">Sim</option>
                                                                <option value="2" :selected="item.pre_jogo == 2">Não</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="d-flex" :class="{'flex-column align-items-center': platform == 'mobile', 'flex-row align-items-center': platform == 'desktop'}">
                                                        <div :class="{'mb-2 text-center': platform == 'mobile', 'mr-3': platform == 'desktop'}">Ao-vivo</div>
                                                        <div>
                                                            <select class="form-control form-control-sm w-auto" v-model="filteredListLeagues[cell.index].ao_vivo">
                                                                <option value="1" :selected="item.ao_vivo == 1">Sim</option>
                                                                <option value="2" :selected="item.ao_vivo == 2">Não</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="d-flex" :class="{'flex-column align-items-center': platform == 'mobile', 'flex-row align-items-center': platform == 'desktop'}">
                                                        <div :class="{'mb-2 text-center': platform == 'mobile', 'mr-3': platform == 'desktop'}">Destacar</div>
                                                        <div>
                                                            <select class="form-control form-control-sm w-auto" v-model="filteredListLeagues[cell.index].destacar">
                                                                <option value="1" :selected="item.destacar == 1">Sim</option>
                                                                <option value="2" :selected="item.destacar == 2">Não</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </collection-cluster>
                                </div>
                            </div><!-- /div --> 
                        </template>                      
                    </div><!-- /div -->
                </div><!-- /mb-3 -->
            </div><!-- /container -->
        </div><!-- /main -->
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import CollectionCluster from 'vue-collection-cluster'

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const heightAdjustMobile = 260;
const heightAdjustDesktop = 150;
const heightAdjustItemDevice = isMobile ? 128 : 62;
const heightAdjust = isMobile ? heightAdjustMobile : heightAdjustDesktop;

export default {
    data() {
        return {
            status: 0,
            error: '',
            search: '',
            leagues: [],
            loading: false,
            collection: {
                heightType: 'automatic',
                itemHeight: heightAdjustItemDevice
            },
            screen_innerHeight: window.innerHeight - heightAdjust,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        Navbar,
        CollectionCluster
    },
    methods: {
        leaguesLoad() {

            const self = this;

            self.error = '';
            self.loading = true;

            api.get('dashboard/leagues').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.leagues = response.data.leagues.map((item) => {
                            return {
                                type: 'item',
                                id: item.id,
                                _id: item._id,
                                nome: item.nome,
                                ao_vivo: item.ao_vivo,
                                pre_jogo: item.pre_jogo,
                                destacar: item.destacar == 'undefined' ? '2' : item.destacar,
                            }
                        }).sort((a, b) => {
                            return a.nome.localeCompare(b.nome)
                        });
                    break;
                    case 'nothing_found':
                        self.error = 'Não encontramos nenhuma liga disponível!'
                    break;
                    default:
                        self.error = response.data.message;
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        save() {

            const self = this;

            Swal.fire({
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                text: 'Salvando alterações...',
                onBeforeOpen: () => {
                  Swal.showLoading()  
                }
            });

            api.post('dashboard/leagues', {
                leagues: self.leagues
            }).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        Swal.fire(
                            'Parabéns!',
                            'As alterações foram salvas com sucesso!',
                            'success'
                        );
                    break;
                    default:
                        Swal.fire(
                            'Falha!',
                            'Não foi posssível salvar as alterações!',
                            'error'
                        );
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'warning'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'warning'
                    );
                }
            });
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
        imageError(event, image) {
            event.target.src = image;
        },
        imageFlag(name) {
            try {

                const state = name.split('-')[0];

                if (typeof(state) !== 'undefined') {
                    return `${this.removeAccents(state).toLowerCase().trim().replace(/[^a-z0-9\s]/g, '').replace(/\s/g, '-')}.png`;
                } else {
                    return 'league.svg';
                }
            } catch(e) {
                return 'league.svg';
            }
        }
    },
    watch: {
        status() {
            this.search = '';
        },
        search() {
            this.status = 0;
        }
    },
    computed: {
        filteredListLeagues() {
            return this.leagues.filter((item) => {
                return this.status != 0 && this.search == '' ? item.pre_jogo == this.status : this.removeAccents(item.nome).toLowerCase().trim().includes(this.removeAccents(this.search).toLowerCase().trim())
            });
        }
    },
    created() {
        
        this.leaguesLoad();

        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.collection-cluster {
    overflow-x: auto;
}
.collection-cluster.desktop::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.collection-cluster.desktop::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.collection-cluster.desktop::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.collection-cluster.desktop::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.collection-cluster.desktop::-webkit-scrollbar-thumb:active {
  background: #ddd;
}
.collection-cluster.desktop::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.collection-cluster.desktop::-webkit-scrollbar-track:hover {
  background: #666666;
}
.collection-cluster.desktop::-webkit-scrollbar-track:active {
  background: #333333;
}
.collection-cluster.desktop::-webkit-scrollbar-corner {
  background: transparent;
}
.loading {
    width: 100%;
    height: 100%;
    top: 70px;
    position: fixed;
    z-index: 1031;
    display: flex;
    background-color: #333;
}
.loading .justify-content-center {
    width: 100%;
    align-items: center;
}
</style>