<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container" :class="{'loading': loading}">
                <iframe @load="loading = false" :src="`https://betsnow.net/help.html?v=${noCache()}`" frameborder="0"></iframe>
            </div>
        </div>
    </main>
</template>

<script>
import Navbar from './Navbar'

export default {
    data() {
        return {
            loading: true
        }
    },
    components: {
        Navbar
    },
    methods: {
        noCache() {
            return (new Date()).getTime();
        }
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.container.loading {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/images/1495.gif);
}
iframe,
.container.loading {
    width: 100%;
    height: calc(100vh - 100px);
}
</style>